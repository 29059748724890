<script setup>
import { onMounted, ref, watch } from 'vue'
import { useProfileInfoInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/profileInfoInventory.store'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
import InventoryService from '@/apps/dailyTech/inventario-ti/services/inventory.service'
import { useRoute, useRouter } from 'vue-router'
import Icon from '@/components/Icon/index.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const _InventoryService = ref(new InventoryService())
const profileInfoInventoryStore = useProfileInfoInventoryStore()
const { _profileInfoInventory } = storeToRefs(profileInfoInventoryStore)
const activeMenuItemIndex = ref(0)
const items = ref([
  {
    label: 'Detalles',
    icon: 'flat-color-icons:view-details',
    command: async () => {
      await router.push({ name: 'daily-tech.inventario-ti.perfil.id.details' })
    },
    name: 'detalles'
  },
  {
    label: 'Actividad',
    icon: 'flat-color-icons:search',
    command: async () => {
      await router.push({ name: 'daily-tech.inventario-ti.perfil.id.activity' })
    },
    name: 'actividad'
  }
])

// Obtener la imagen del registro inventario
const getImageInventory = (image) => {
  // Detectar si la imagen es object
  if (typeof image === 'object') {
    return URL.createObjectURL(image)
  }

  const token = store.getters['auth/getToken']
  const obj = { image, token }
  return _InventoryService.value.getImageInventory(obj)
}

// Verificar la ruta activa
watch(
  () => route.path,
  (updatedPath) => {
    activeMenuItemIndex.value = items.value.findIndex((item) => updatedPath.includes(item.name))
  },
  { immediate: true }
)

// Hook
onMounted(async () => {
  // Obtener la información del registro
  await profileInfoInventoryStore._actionProfileInfoInventory(route.params)
})
</script>

<template>
  <Toolbar class="box mt-4 p-2">
    <template #start>
      <h2 class="text-lg font-bold truncate mr-5">Perfil Inventario TI</h2>
    </template>
    <template #end>
      <Button
        label="Regresar"
        icon="pi pi-arrow-left"
        class="p-button-sm"
        @click="$router.push({ name: 'daily-tech.inventario-ti.listado'})"/>
    </template>
  </Toolbar>

  <!-- Info del registro -->
  <div class="box mt-4 p-4" style="border-top-left-radius: 6px; border-top-right-radius: 6px;  border-top-color: #1c3faa; border-top-width: 8px;">
    <div class="grid grid-cols-12 gap-4">

      <div class="col-span-12 sm:col-span-12 md:col-span-12">
        <h3 class="text-base text-gray-600 font-bold mb-4">Información General</h3>
        <div class="flex flex-wrap flex-col sm:flex-row justify-between items-center gap-4">
          <!-- Imagen, tipo, nombre y id inventario dispositivo -->
          <div clasS="flex flex-wrap flex-col sm:flex-row justify-between items-center gap-4">
            <div class="flex flex-wrap justify-center items-center bg-gray-200 rounded-md" style="width: 100px; height: 100px">
              <Image
                v-if="_profileInfoInventory.photo"
                :src="getImageInventory(_profileInfoInventory.photo)"
                alt="Image"
                preview
                :pt="{
                  root: { class: 'flex justify-center items-center w-full h-full bg-gray-200 rounded-md' },
                  image: { style: 'width: 100px; height: 100px; object-fit: contain;' }
                }"
              />
              <Icon v-else :icon="_profileInfoInventory.typeDeviceIcon ? _profileInfoInventory.typeDeviceIcon : 'material-symbols:devices-off-outline-rounded'" style="opacity: 0.4; font-size: 5rem" />
            </div>
            <div class="flex flex-col">
              <span :class="{
                'text-sm text-yellow-800 font-light italic opacity-50': !_profileInfoInventory.attributes?.nombre,
                'text-gray-500 text-xl font-semibold': _profileInfoInventory.attributes?.nombre
              }">{{ _profileInfoInventory.attributes?.nombre ?? '(Sin nombre)' }}</span>
              <span class="text-gray-600 text-base font-bold">{{ _profileInfoInventory.typeDeviceName }}</span>
              <span :class="{
                'text-sm text-yellow-800 font-light italic opacity-50': !_profileInfoInventory.attributes?.idInventario,
                'text-blue-700 text-sm font-semibold': _profileInfoInventory.attributes?.idInventario
              }"> # {{ _profileInfoInventory.attributes?.idInventario ?? '(Sin asignar)' }}</span>
            </div>
          </div>

          <!-- Información de Estado, ubicación, dueño, marca y modelo -->
          <div class="flex flex-col sm:flex-row flex-wrap">
            <!-- Estado -->
            <div class="flex flex-wrap items-center">
              <Icon
                :icon="_profileInfoInventory.statusIcon"
                class="text-3xl text-green-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Estado:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _profileInfoInventory.statusName ?? 'Sin asignar' }}</span>
              </div>
            </div>
            <!-- Ubicación -->
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:map-marker"
                class="text-3xl text-red-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Ubicación:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _profileInfoInventory.locationName ?? 'Sin asignar' }}</span>
              </div>
            </div>
            <!-- Dueño -->
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:account"
                class="text-3xl text-yellow-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Dueño:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _profileInfoInventory.ownerName ?? 'Sin asignar' }}</span>
              </div>
            </div>
            <!-- Marca -->
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:registered-trademark"
                class="text-3xl text-gray-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Marca:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _profileInfoInventory.brandName ?? 'Sin asignar' }}</span>
              </div>
            </div>
            <!-- Modelo -->
            <div class="flex flex-wrap items-center">
              <Icon
                icon="mdi:devices"
                class="text-3xl text-blue-700 font-bold py-1 px-4"
              />
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Modelo:</span>
                <span class="text-gray-500 text-sm font-semibold">{{ _profileInfoInventory.modelName ?? 'Sin asignar' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Tabs y sus contenidos -->
  <section class="contenido p-mt-2">
    <TabMenu
      :model="items"
      class="tab-menu pb-2"
      :active-index="activeMenuItemIndex"
      :pt="{
        // Tabs full width
        menu: { style: 'width: 100%; display: flex; justify-content: center;' },
        menuitem: { style: 'width: 100%; display: flex; justify-content: space-between;' },
        action: { style: 'width: 100%; display: flex; justify-content: center;' }
      }"
    >
      <template #item="{ item }">
        <a
          class="p-menuitem-link flex justify-center items-center w-full gap-2"
          style="border-width: 0 0 3px 0; padding: 0.875rem"
        >
          <Icon :icon="item.icon" class="text-2xl" />
          <span class="font-bold">{{ item.label }}</span>
        </a>
      </template>
    </TabMenu>
    <router-view />
  </section>

<!--  <pre style="font-size: 11px; line-height: 1"><code>{{ JSON.stringify(_profileInfoInventory, null, '\t') }}</code></pre>-->
</template>

<style scoped>

</style>
